$(document).ready(function() {
	if(!document.querySelector('.C0065')) return false;
	
	var $obj = $('.C0065');
	var $slick = $obj.find('.product-type .items').slick({
		infinite: false, //LGEGMO-5525
		listStyle: true, //LGEGMO-5525
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows : true,
		dots: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
			  breakpoint: 768,
			  settings: {
				autoplay: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				dots: true
			 }
			}
		],
		prevArrow: carouselOptions.bigAnglePrev, // common.js variable
		nextArrow: carouselOptions.bigAngleNext // common.js variable
	});

	setTimeout( function() {
		$('.C0065').find('.items .slick-prev').attr('role', 'Arrow');
		$('.C0065').find('.items .slick-next').attr('role', 'Arrow');
		$('.C0065').find('.items .slick-prev').attr('aria-label', 'Previous Arrow');
		$('.C0065').find('.items .slick-next').attr('aria-label', 'Next Arrow');
	},3000);

	$('.C0065 .slick-track li').attr({
		tabindex: "-1"
	});	//DC-717

	// link slick 
	$obj.find('.item a.use-filter').on('click', function(e) {
		e.preventDefault();
		var linkUrl = $(this).attr('href');
		var currURL = location.pathname;
		var title = changeTitleFormat($(this).find('.model-name.top').eq(0).text());
		if(!title || title=='') title = changeTitleFormat($(this).find('.model-name.bottom').eq(0).text());
		adobeSatellite('link_click', {'data-link-area':"category_selector-image", 'data-link-name':title});
		//console.log(linkUrl, currURL, linkUrl.indexOf(currURL));
		//if(linkUrl.indexOf(currURL)!=-1) {
			var filter = $(this).data('filter');
			var filterName = filter.split('=')[0];
			var filterValue = filter.split('=')[1];
			var obj = $('input[name='+filterName+'][value='+filterValue+']');
			if(obj.length>0) {
				var resetBtn = $('.filter-box .etc-box button[type=reset]');
				if(resetBtn.length>0) resetBtn.trigger('click');
				$('input[name='+filterName+']').prop('checked', false)
				if(!obj.prop('checked')) {
					//obj.prop('checked', 'checked');
					obj.trigger('click');
				}
				var t = parseInt($('.filter-box').offset().top);
				setTimeout(function() {
					$('html, body').stop().animate({scrollTop:(t)}, 500);
					$('.filter-box').attr('tabindex', -1).focus();
				}, 500)
			} else {
				location.href=linkUrl;
			}
		//} else {
			// url이 현재 페이지와 다른 경우 
		//	location.href=linkUrl;
		//}
	});
});
